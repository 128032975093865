import { Button, Popconfirm } from 'antd';
import type { AxiosResponse } from 'axios';
import type { Key } from 'react';

export interface BatchActionType {
  isAsync?: boolean;
  label: string;
  onClick: (ids: string[]) => Promise<AxiosResponse<''>[]> | Promise<void> | void;
}

interface BatchActionsProps<T> {
  batchActions: BatchActionType[];
  onFinish: () => Promise<ApiResponse<T[], IndexPageMeta> | null>;
  selectedRowKeys: Key[];
}

export const BatchActions = <T,>({
  batchActions,
  onFinish,
  selectedRowKeys
}: BatchActionsProps<T>) => {
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {batchActions.map(action => {
        const isPromiseAction = action.isAsync ?? true;

        const button = (
          <Button
            key={action.label}
            disabled={!hasSelected}
            onClick={
              !isPromiseAction ? () => action.onClick(selectedRowKeys as string[]) : undefined
            }
            style={{ marginBottom: '5px' }}
            type="primary"
          >
            {action.label}
          </Button>
        );

        return isPromiseAction ? (
          <Popconfirm
            key={action.label}
            cancelText="No"
            okText="Yes"
            onConfirm={() => {
              const result = action.onClick(selectedRowKeys as string[]);
              if (result) result.then(onFinish);
            }}
            title="Are you sure?"
          >
            {button}
          </Popconfirm>
        ) : (
          button
        );
      })}
    </>
  );
};
