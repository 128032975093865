import { Card, Spin } from 'antd';
import { TabsProps } from 'antd/lib';
import { getNpiRegistryData, getUser, useFetch } from 'api';
import { CardEntryList } from 'components/cards/card_entry_list';
import PageStickyHeader from 'components/page_sticky_header';
import { NpiRegistryDatumCard } from 'pages/npi_registry';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import BenchmarksSummaryTable from './userPageComponents/benchmarks_summary_table';
import CommunicationsTable from './userPageComponents/communications_table';
import CourseCollectionCompletionsTable from './userPageComponents/course_collection_completions_table';
import CourseRatingsTable from './userPageComponents/course_ratings_table';
import CourseRegistrationsTable from './userPageComponents/course_registrations_table';
import CulturalCompetenciesTable from './userPageComponents/cultural_competencies_table';
import EducationsTable from './userPageComponents/educations_table';
import FurtherExperienceTable from './userPageComponents/further_experiences_table';
import NonClinicalExperiencesTable from './userPageComponents/non_clinical_experiences_table';
import NotificationsTable from './userPageComponents/notifications_table';
import OrganizationMembershipsTable from './userPageComponents/organization_memberships_table';
import ScoresHistoryTable from './userPageComponents/scores_history_table';
import UserExperiencesTable from './userPageComponents/user_experiences_table';
import UserInfoTable from './userPageComponents/user_info_table';
import UserPageHeader from './userPageComponents/user_page_header';
import { userPageMainFields } from './userPageComponents/user_page_main_fields';

export const UserPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userData, isLoading, refetch } = useFetch(getUser, [id]);
  const [tabContent, setTabContent] = useState<TabsProps['items']>([]);

  const npiGetParams = useMemo(() => ['1', '10', { user_id: id }], [id]) as [
    string,
    string,
    object
  ];
  const { data: npiRegistryData } = useFetch(getNpiRegistryData, npiGetParams);

  useEffect(() => {
    setTabContent([
      {
        children: userData?.data ? (
          <CardEntryList
            fieldsMap={userPageMainFields}
            values={userData.data}
          />
        ) : (
          <Spin size="large" />
        ),
        key: 'account',
        label: `Account`
      },
      {
        children: userData?.data ? (
          <BenchmarksSummaryTable user_info_id={userData.data.user_info.id} />
        ) : (
          <Spin size="large" />
        ),
        key: 'benchmarks',
        label: 'Benchmarks'
      },
      {
        children: userData?.data ? (
          <UserInfoTable userInfo={userData.data.user_info} />
        ) : (
          <Spin size="large" />
        ),
        key: 'user_info',
        label: 'User Info'
      },
      {
        children: userData?.data ? (
          <OrganizationMembershipsTable userData={userData.data} />
        ) : (
          <Spin size="large" />
        ),
        key: 'org_memberships',
        label: 'Organization Memberships'
      },
      {
        children: userData?.data ? (
          <EducationsTable user_id={userData.data.id} />
        ) : (
          <Spin size="large" />
        ),
        key: 'educations',
        label: 'Educations'
      },
      {
        children: userData?.data ? (
          <UserExperiencesTable experiences={userData.data.user_info.experiences} />
        ) : (
          <Spin size="large" />
        ),
        key: 'experiences',
        label: 'Experiences'
      },
      {
        children: userData?.data ? (
          <NonClinicalExperiencesTable user={userData.data} />
        ) : (
          <Spin size="large" />
        ),
        key: 'non_clinical_experience',
        label: 'Non-clinical Experiences'
      },
      {
        children: userData?.data ? (
          <FurtherExperienceTable user={userData.data} />
        ) : (
          <Spin size="large" />
        ),
        key: 'further_experience',
        label: 'Further Experience'
      },
      {
        children: userData?.data ? (
          <CulturalCompetenciesTable
            culturalCompetences={userData.data.user_info.cultural_competences}
          />
        ) : (
          <Spin size="large" />
        ),
        key: 'competences',
        label: 'Cultural Competences'
      },
      {
        children: userData?.data ? (
          <ScoresHistoryTable user_info_id={userData.data.user_info.id} />
        ) : (
          <Spin size="large" />
        ),
        key: 'scores',
        label: 'Scores History'
      },
      {
        children: userData?.data ? (
          <NotificationsTable user_id={userData.data.id} />
        ) : (
          <Spin size="large" />
        ),
        key: 'notifications',
        label: 'Notifications'
      },
      {
        children: userData?.data ? (
          <CommunicationsTable user_id={userData.data.id} />
        ) : (
          <Spin size="large" />
        ),
        key: 'emails',
        label: 'E-mails History'
      },
      {
        children: userData?.data ? (
          <CourseRatingsTable user_id={userData.data.id} />
        ) : (
          <Spin size="large" />
        ),
        key: 'ratings',
        label: 'Course ratings'
      },
      {
        children: userData?.data ? (
          <CourseRegistrationsTable user_id={userData.data.id} />
        ) : (
          <Spin size="large" />
        ),
        key: 'registrations',
        label: 'Course registrations'
      },
      {
        children: userData?.data ? (
          <CourseCollectionCompletionsTable user_id={userData.data.id} />
        ) : (
          <Spin size="large" />
        ),
        key: 'collection_completions',
        label: 'Collection completions'
      },
      {
        children: npiRegistryData?.data ? (
          npiRegistryData.data.map(datum => (
            <NpiRegistryDatumCard
              key={datum.id}
              initialDatum={datum}
            />
          ))
        ) : (
          <Spin size="large" />
        ),
        key: 'npi_data',
        label: 'NPI Registry Data'
      }
    ]);
  }, [userData, npiRegistryData]);

  if (!userData) return <Spin size="large" />;

  const topBarSections = [
    {
      label: 'ID',
      value: userData.data?.id ?? '--'
    },
    {
      label: 'Name',
      value: `${userData.data?.first_name} ${userData.data?.last_name}`
    },
    {
      label: 'Email',
      value: userData.data?.email ?? '--'
    },
    {
      label: 'NPI',
      value: userData.data?.user_info.npi ?? '--'
    }
  ];

  return (
    <div className="site-card-border-less-wrapper">
      {userData.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <UserPageHeader
              isLoading={isLoading}
              refetchUser={refetch}
              user={userData.data}
            />
          }
        >
          <PageStickyHeader
            tabContent={tabContent}
            topBarSections={topBarSections}
          />
        </Card>
      )}
    </div>
  );
};
