import { DownloadOutlined } from '@ant-design/icons';
import { Button, Empty, Table, TableProps } from 'antd';
import { useFetch } from 'api';
import { Dispatch, Key, SetStateAction, useEffect, useMemo, useState } from 'react';

import { BatchActions, BatchActionType } from './batch_actions';
import { FilterType, TableFilters } from './table_filters';

type DataTableProps<T, U> = TableProps<T> & {
  batchActions?: BatchActionType[];
  exportFileName?: string;
  exportMethod?: (exportParams?: object) => Promise<ApiResponse<BlobPart>>;
  filters?: FilterType<U>[];
  getMethod: (
    page?: string,
    pageSize?: string,
    getParams?: object
  ) => Promise<ApiResponse<T[], IndexPageMeta>>;
  getParams?: Record<string, string[] | boolean | string>;
  otherActions?: React.ReactNode;
  perPage?: number;
  refetchTrigger?: number;
  searchTerm?: string;
  updateDataUpstream?: Dispatch<SetStateAction<T[]>>;
  updateIsLoadingUpstream?: Dispatch<SetStateAction<boolean>>;
};

export const DataTable = <T extends object, U>({
  batchActions,
  exportFileName,
  exportMethod,
  filters,
  getMethod,
  getParams,
  otherActions,
  pagination,
  perPage = 10,
  refetchTrigger,
  searchTerm,
  style,
  updateDataUpstream,
  updateIsLoadingUpstream,
  ...props
}: DataTableProps<T, U>) => {
  /*
    FilterType[] => [{ [defaultKey]: defaultValue }] => { defaultKey: defaultValue }
  */
  const defaultFilters = filters
    ?.filter(filter => filter.defaultValue !== undefined)
    .map(filter => ({ [filter.key]: filter.defaultValue }))
    .reduce<Record<string, string[] | boolean | string | null | undefined>>(function (
      result,
      current
    ) {
      return Object.assign(result, current);
    }, {});

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(perPage);
  const [filterData, setFilterData] = useState({ ...defaultFilters });
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const params = useMemo(() => ({ ...getParams, ...filterData }), [getParams, filterData]);

  const { data, isLoading, refetch } = useFetch(getMethod, [`${page}`, `${pageSize}`, params]);

  useEffect(() => {
    if (typeof updateDataUpstream === 'function') {
      updateDataUpstream(data?.data ?? []);
    }
  }, [data?.data, updateDataUpstream]);

  useEffect(() => {
    if (typeof updateIsLoadingUpstream === 'function') {
      updateIsLoadingUpstream(isLoading);
    }
  }, [isLoading, updateIsLoadingUpstream]);

  useEffect(() => {
    if (refetchTrigger === undefined) return;
    refetch();
  }, [refetchTrigger, refetch]);

  const onExport = async () => {
    if (exportMethod === undefined) return;

    const response = await exportMethod(params);
    const csvContent = new Blob([response as BlobPart], { type: 'text/csv' });

    const csvUrl = window.URL.createObjectURL(csvContent);

    const link = document.createElement('a');
    link.href = csvUrl;
    link.setAttribute('download', exportFileName ?? 'export.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(csvUrl);
  };

  const rowSelection: TableProps<T>['rowSelection'] = batchActions
    ? {
        onChange: selectedRowKeys => {
          setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys
      }
    : undefined;

  return (
    <>
      <div style={{ display: 'flex' }}>
        {batchActions && (
          <BatchActions
            batchActions={batchActions}
            onFinish={refetch}
            selectedRowKeys={selectedRowKeys}
          />
        )}
        {filters && (
          <TableFilters
            defaultFilters={defaultFilters}
            filters={filters}
            onExport={exportMethod !== undefined ? onExport : undefined}
            setFilterData={setFilterData}
          />
        )}
        {otherActions !== undefined && (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '0.5rem'
            }}
          >
            {otherActions}
          </div>
        )}
        {!filters && exportMethod !== undefined && (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '0.5rem'
            }}
          >
            <Button
              onClick={onExport}
              size="middle"
            >
              <DownloadOutlined /> Export
            </Button>
          </div>
        )}
      </div>

      <Table
        {...props}
        bordered
        dataSource={data?.data?.map((entry, index) => ({ key: index, ...entry }))}
        loading={isLoading}
        locale={{
          emptyText: (
            <Empty
              description="Empty"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )
        }}
        onChange={(pagination, filters, sorter) => {
          /* @ts-expect-error */
          if (Boolean(sorter.columnKey) && Boolean(sorter.order)) {
            setFilterData({
              ...pagination,
              ...filters,
              order_by: {
                // @ts-expect-error
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                column: sorter.columnKey,
                // @ts-expect-error, eslint-disable-next-line
                dir: sorter.order === 'ascend' ? 'asc' : 'desc'
              }
            });
          }
        }}
        pagination={
          pagination !== undefined
            ? pagination
            : {
                current: page,
                onChange: (nextPage, newPageSize) => {
                  newPageSize && setPageSize(newPageSize);
                  setPage(nextPage);
                },
                pageSize,
                showSizeChanger: true,
                showTotal: (total: number) => (
                  <>
                    Total <b>{total}</b> records
                  </>
                ),
                total: data?.meta?.total_count
              }
        }
        rowSelection={rowSelection}
        style={{ height: '100%', marginTop: '5px', width: '100%', ...style }}
      />
    </>
  );
};
