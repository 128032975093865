import { LogoutOutlined } from '@ant-design/icons';
import { LogoutOptions } from '@auth0/auth0-react';
import { Button, Popconfirm } from 'antd';
import { store } from 'store';

interface SignOutProprs {
  logoutAuth0: (options?: LogoutOptions) => void;
}

export const SignOut = ({ logoutAuth0 }: SignOutProprs) => {
  const handleLogout = () => {
    logoutAuth0({
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI
      }
    });
    store.dispatch('signOut');
  };

  return (
    <Popconfirm
      cancelText="No"
      okText="Yes"
      onConfirm={handleLogout}
      title="Are you sure you want to log out?"
    >
      <Button>
        <LogoutOutlined /> Log out
      </Button>
    </Popconfirm>
  );
};
