import { WarningOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getUser, GetUserResponse } from 'api';
import { UserLink } from 'components/common';
import { useEffect, useState } from 'react';

const UserCell = ({ user_id }: { user_id: string }) => {
  const [userData, setUserData] = useState<GetUserResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await getUser(user_id);
        setUserData(response.data ?? null);
      } catch (error) {
        console.error('Error fetching user:', error);
        setError('Failed to load user data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();

    return () => {
      setUserData(null);
      setError(null);
    };
  }, [user_id]);

  if (isLoading) return <Spin />;
  if (error !== null && error !== '')
    return (
      <span>
        <WarningOutlined /> {user_id}
      </span>
    );
  return userData ? <UserLink user={userData} /> : <span>{user_id}</span>;
};

export default UserCell;
