import { Spin, Tooltip } from 'antd';
import { getNetworkAnalytics, GetNetworkAnalyticsResponse } from 'api';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const TooltipValue = styled.span`
  border-bottom: 1px dashed;
  cursor: help;
`;

interface Props {
  networkId: string;
}

export const NetworkAnalyticsPreview = ({ networkId }: Props) => {
  const [isFetching, setIsFetching] = useState(true);
  const [response, setResponse] = useState<GetNetworkAnalyticsResponse | null>(null);

  useEffect(() => {
    if (isFetching) {
      getNetworkAnalytics(networkId).then(data => {
        setIsFetching(false);
        setResponse(data);
      });
    }
  }, [networkId, isFetching]);

  return isFetching && response === null ? (
    <div style={{ textAlign: 'center' }}>
      <Spin />
    </div>
  ) : (
    <table>
      <tbody>
        <tr>
          <td width="33%">{response?.data.total_organization_members}</td>
          <td width="33%">
            <Tooltip title={response?.data.queries.total_providers_query}>
              <TooltipValue>{response?.data.total_providers}</TooltipValue>
            </Tooltip>
          </td>
          <td width="33%">
            <Tooltip title={response?.data.queries.total_onboarded_providers_query}>
              <TooltipValue>{response?.data.total_onboarded_providers}</TooltipValue>
            </Tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
