import { Tabs, TabsProps, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import PageTopBar from './page_top_bar';

interface Props {
  tabContent: TabsProps['items'];
  topBarSections: {
    label: string;
    value: string;
  }[];
}

const PageStickyHeader = ({ tabContent, topBarSections }: Props) => {
  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const location = useLocation();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(
    location.hash.slice(1) !== '' ? location.hash.slice(1) : tabContent?.[0]?.key
  );

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <StickyBox
      className="sticky-box"
      offsetBottom={20}
      offsetTop={0}
      style={{ background: colorBgContainer, zIndex: 5 }}
    >
      {topBarSections.length > 0 && <PageTopBar sections={topBarSections} />}
      <DefaultTabBar
        {...props}
        style={{ background: colorBgContainer, fontWeight: '500' }}
      />
    </StickyBox>
  );

  const handleTabClick = (key: string) => {
    history.push(`#${key}`);
  };

  useEffect(() => {
    if (location.hash) {
      const tab = location.hash.slice(1);
      if (
        tabContent !== undefined &&
        tabContent.length > 0 &&
        tabContent.some(t => t.key === tab)
      ) {
        setActiveKey(tab);
      }
    }
  }, [location.hash, tabContent]);

  return (
    <Tabs
      activeKey={activeKey}
      defaultActiveKey={
        location.hash.slice(1) !== '' ? location.hash.slice(1) : tabContent?.[0]?.key
      }
      items={tabContent}
      onChange={handleTabClick}
      renderTabBar={renderTabBar}
    />
  );
};

export default PageStickyHeader;
