import type { DatePickerProps } from 'antd';
import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne, SetNonNullable } from 'type-fest';

import { instance, VendorNames } from '.';

interface Bookmark {
  assigned_by_id: string;
  assigned_by_type: 'Network' | 'Organization';
  created_by_id: string | null;
  email_send_date: string | null;
  end_date: string | null;
  id: string;
  organization_id: string | null;
  resource:
    | {
        author: string;
        bipoc_communities: boolean;
        ce_credits: string;
        created_at: string;
        full_title: string;
        hours: string;
        id: string;
        lgbq_communities: boolean;
        link: string;
        other_communities: boolean;
        published: boolean;
        scormcloud_id: string | null;
        short_title: string;
        tgnc_communities: boolean;
        updated_at: string;
        year: number;
      }
    | {
        communities: ScoreType[];
        created_at: string;
        credits: string;
        description: string;
        external_accreditation_references: {
          id: string;
          vendor_name: VendorNames;
          vendor_resource_id: string;
        }[];
        hours: string;
        id: string;
        learning_objectives: string[];
        level: 'advanced' | 'beginner' | 'foundation' | 'intermediate';
        name: string;
        published: 'not_published' | 'pinned' | 'published';
        specialties: string[];
        updated_at: string;
      };
  resource_id: string;
  resource_type: 'Course' | 'CourseCollection';
  start_date: string | null;
  user_id: string | null;
  user_name: string | null;
}

interface BatchCreateBookmarksParams {
  bookmarks: {
    assigned_by_id: Bookmark['assigned_by_id'];
    assigned_by_type: Bookmark['assigned_by_type'];
    email_send_date?: DatePickerProps['value'];
    end_date?: DatePickerProps['value'];
    resource_id: Bookmark['resource_id'];
    resource_type: Bookmark['resource_type'];
    start_date?: DatePickerProps['value'];
    user_id?: Bookmark['user_id'];
  }[];
}

interface BatchCreateBookmarksResponse extends SetNonNullable<Bookmark, 'created_by_id'> {}

export interface GetBookmarkResponse extends Bookmark {}

export interface GetBookmarksResponse extends Bookmark {}

interface UpdateBookmarkParams {
  bookmark: RequireAtLeastOne<
    Omit<Bookmark, 'email_send_date' | 'end_date' | 'organization_id' | 'start_date'> & {
      email_send_date: DatePickerProps['value'] | null;
      end_date: DatePickerProps['value'] | null;
      start_date: DatePickerProps['value'] | null;
    }
  >;
}

export const batchCreateBookmarks = (params: BatchCreateBookmarksParams) =>
  instance
    .post<ApiResponse<BatchCreateBookmarksResponse>>('/admin/bookmarks/batch', params)
    .then(res => res.data);

export const deleteBookmark = (id: string) => instance.delete<''>(`/admin/bookmarks/${id}`);

export const getBookmark = (id: string) =>
  instance.get<ApiResponse<GetBookmarkResponse>>(`/admin/bookmarks/${id}`).then(res => res.data);

export const getBookmarks = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetBookmarksResponse[], IndexPageMeta>>('/admin/bookmarks', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const updateBookmark = (id: string, params: UpdateBookmarkParams) =>
  instance.patch<'', AxiosResponse<''>, UpdateBookmarkParams>(`/admin/bookmarks/${id}`, params);
