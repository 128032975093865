import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

import { instance } from '.';

interface CreateNetworkParams {
  network: {
    default_comparison_states: string[];
    name: string;
    organization_manager_ids?: string[];
    organization_member_ids?: string[];
  };
}

export interface GetNetworkAnalyticsResponse {
  data: {
    id: string;
    queries: {
      total_onboarded_providers_query: string;
      total_providers_query: string;
    };
    total_onboarded_providers: number;
    total_organization_members: number;
    total_providers: number;
  };
}

interface CreateNetworkLocationSourceParams {
  network_location_source: {
    name: string;
  };
}

export interface GetNetworkLocationSourcesResponse {
  created_at: string;
  id: string;
  name: string;
  updated_at: string;
}

export interface GetNetworkResponse {
  created_at: string;
  default_comparison_states: string[];
  id: string;
  name: string;
  organization_managers: {
    created_at: string;
    id: string;
    name: string;
  }[];
  organization_members: {
    created_at: string;
    direct_contract: boolean;
    id: string;
    name: string;
  }[];
  total_onboarded_providers: number;
  total_organization_members: number;
  total_providers: number;
  updated_at: string;
}

export interface GetNetworksResponse {
  created_at: string;
  id: string;
  name: string;
  total_organization_members: number;
  updated_at: string;
}

interface UpdateNetworkParams {
  network: RequireAtLeastOne<{
    default_comparison_states: string[];
    name: string;
    organization_manager_ids: string[];
    organization_member_ids: string[];
  }>;
}

interface UpdateNetworkLocationSourceParams {
  network_location_source: {
    name: string;
  };
}

export const createNetwork = (params: CreateNetworkParams) =>
  instance.post<'', AxiosResponse<''>, CreateNetworkParams>('/admin/networks', params);

export const createNetworkLocationSource = (
  id: string,
  params: CreateNetworkLocationSourceParams
) =>
  instance.post<
    '',
    AxiosResponse<{ data: GetNetworkLocationSourcesResponse }>,
    CreateNetworkLocationSourceParams
  >(`/admin/networks/${id}/location_sources`, params);

export const deleteNetwork = (id: string) => instance.delete<''>(`/admin/networks/${id}`);

export const deleteNetworkLocationSource = (networkId: string, locationSourceId: string) =>
  instance.delete<''>(`/admin/networks/${networkId}/location_sources/${locationSourceId}`);

export const getNetwork = (id: string) =>
  instance.get<ApiResponse<GetNetworkResponse>>(`/admin/networks/${id}`).then(res => res.data);

export const getNetworkAnalytics = (id: string) =>
  instance
    .get<GetNetworkAnalyticsResponse>(`/admin/networks/${id}/analytics`)
    .then(res => res.data);

export const getNetworkLocationSources = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetNetworkLocationSourcesResponse[], IndexPageMeta>>(
      `/admin/networks/location_sources`,
      {
        params: { ...getParams, page, per_page: pageSize }
      }
    )
    .then(res => res.data);

export const getNetworks = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetNetworksResponse[], IndexPageMeta>>('/admin/networks', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const updateNetwork = (id: string, params: UpdateNetworkParams) =>
  instance.patch<'', AxiosResponse<''>, UpdateNetworkParams>(`/admin/networks/${id}`, params);

export const updateNetworkLocationSource = (
  networkId: string,
  locationSourceId: string,
  params: UpdateNetworkLocationSourceParams
) =>
  instance.patch<'', AxiosResponse<''>, UpdateNetworkLocationSourceParams>(
    `/admin/networks/${networkId}/location_sources/${locationSourceId}`,
    params
  );
