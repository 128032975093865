import { Card, FormProps, Spin } from 'antd';
import { getOrganization, updateOrganization, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { renameAndFillNestedAttributes } from 'utils/helpers';
import * as paths from 'utils/paths';

import { OrganizationForm } from './organization_form';

export const OrganizationEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getOrganization, [id]);

  const navigate = useNavigate();

  const onFinish: FormProps<{
    hubspot_id: number;
    name: string;
    network: boolean;
    organization_email_domains: (
      | {
          _destroy: boolean;
          domain: string;
          id: string;
        }
      | {
          domain: string;
          id: string;
        }
    )[];
    organization_external_identifiers: (
      | {
          _destroy: boolean;
          id: string;
          identifier: string;
          identifier_type: string;
          source?: string | null;
        }
      | {
          id: string;
          identifier: string;
          identifier_type: string;
          source?: string | null;
        }
    )[];
    organization_license_tiers: (
      | {
          _destroy: boolean;
          id: string;
        }
      | {
          available_licenses: number;
          id: string;
          license_tier_id: string;
          total_licenses: number;
        }
    )[];
    referral_code: string | null;
    sso_connection_id: string | null;
    unlimited_license: boolean;
  }>['onFinish'] = values => {
    const {
      organization_email_domains,
      organization_external_identifiers,
      organization_license_tiers,
      referral_code,
      ...orgValues
    } = values;

    return updateOrganization(id, {
      organization: {
        referral_code: referral_code === '' ? null : referral_code,
        ...orgValues,
        ...renameAndFillNestedAttributes(
          {
            // @ts-expect-error
            organization_license_tiers
          },
          data?.data
        ),
        ...renameAndFillNestedAttributes(
          {
            // @ts-expect-error
            organization_email_domains
          },
          data?.data
        ),
        ...renameAndFillNestedAttributes(
          {
            // @ts-expect-error
            organization_external_identifiers
          },
          data?.data
        )
      }
    }).then(({ data }) => {
      navigate(paths.organizationPath(data.id));
    });
  };

  if (!data?.data) return <Spin />;

  return (
    <Card title={<CardHeader title={`Edit organization ${data.data.name}`} />}>
      <OrganizationForm
        initialData={data.data}
        name="organization-edit"
        onFinish={onFinish}
      />
    </Card>
  );
};
