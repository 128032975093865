import { updateNetwork } from 'api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { STATE_LISTS, StateList } from './state_options';

const useStateManagement = (defaultStates: string[], networkId: string) => {
  const [originalStates, setOriginalStates] = useState<string[]>(defaultStates);
  const [states, setStates] = useState<string[]>(defaultStates);
  const [stateList, setStateList] = useState<StateList>(STATE_LISTS);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const remainingStates = STATE_LISTS.filter(state => !defaultStates.includes(state.state_code));
    setStateList(remainingStates);
  }, [defaultStates]);

  const handleSave = async () => {
    try {
      await updateNetwork(networkId, {
        network: {
          default_comparison_states: Array.from(new Set(states)).sort()
        }
      });
      toast.success(`Changes saved.`);
      setOriginalStates(states);
      setIsEditing(false);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
      toast.error(`Failed to save changes. ${errorMessage}`);
    }
  };

  const onCancel = () => {
    setStates(originalStates);
    setIsEditing(false);
  };

  return {
    handleSave,
    isEditing,
    onCancel,
    setIsEditing,
    setStates,
    stateList,
    states
  };
};

export default useStateManagement;
