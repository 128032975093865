import { Alert, Button, Card, Form, Space, Spin, Typography } from 'antd';
import { getUser, getUsers, migrateUserData, useFetch } from 'api';
import { CardHeader } from 'components/cards';
import { SearchInputItem } from 'components/forms';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { userPath } from 'utils/paths';

const { Text } = Typography;

// Define the migration result type to fix TypeScript errors
interface MigrationResult {
  migrated_certificates: string[];
  migrated_collections: string[];
  migrated_courses: string[];
  migrated_educations: string[];
  migrated_evaluations: string[];
  migrated_pathways: string[];
  total_records: number;
}

export const UserMergeAccountsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userData } = useFetch(getUser, [id]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [migrationResult, setMigrationResult] = useState<MigrationResult | null>(null);

  const handleMigrate = (values: { from_user_id: string }) => {
    setLoading(true);
    setMigrationResult(null);
    setError(null);

    // Use Promise handling to properly type the response
    Promise.resolve(migrateUserData(id, values.from_user_id))
      .then((result: MigrationResult) => {
        setMigrationResult(result);
      })
      .catch((error: unknown) => {
        console.error('Migration failed:', error);
        setError('Migration failed. Please try again or contact support.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    navigate(userPath(id));
  };

  if (!userData?.data) return <Spin size="large" />;

  const targetUser = userData.data;

  return (
    <Card
      bordered={false}
      title={
        <CardHeader title={`Merge accounts - ${targetUser.first_name} ${targetUser.last_name}`} />
      }
    >
      <Space
        direction="vertical"
        style={{ width: '100%' }}
      >
        <Alert
          description={
            <>
              <Text>
                Select a user whose data you want to migrate to {targetUser.first_name}{' '}
                {targetUser.last_name}. The following data will be migrated:
              </Text>
              <ul>
                <li>Course completions</li>
                <li>Collection completions</li>
                <li>Certificates</li>
                <li>Evaluations</li>
                <li>Education records</li>
                <li>Pathways</li>
              </ul>
              <Text strong>
                Any other data (e.g. onboarding data, user profile information) is not currently
                migrated and the originating user account will be kept intact and may require manual
                deactivation afterward.
              </Text>
            </>
          }
          message="Data Migration"
          showIcon
          type="info"
        />

        <Form
          layout="vertical"
          onFinish={handleMigrate}
        >
          <SearchInputItem
            filterName="search"
            getMethod={getUsers}
            getMethodArgs={[1, 10]}
            label="Select user to migrate data from"
            name="from_user_id"
            paramsMethod={user => ({
              name: `${user.first_name} ${user.last_name} (${user.email}) [ID: ${user.id}]`,
              value: user.id
            })}
            required
            rules={[{ message: 'Please select a user', required: true }]}
            selectProps={{
              style: { width: '100%' }
            }}
          />

          <Form.Item>
            <Space>
              <Button
                htmlType="submit"
                loading={loading}
                type="primary"
              >
                Migrate Data
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>

        {error !== null && (
          <Alert
            description={error}
            message="Error"
            showIcon
            type="error"
          />
        )}

        {migrationResult && (
          <Space
            direction="vertical"
            style={{ display: 'flex', marginTop: '20px' }}
          >
            <Alert
              description={
                <>
                  <Text>Successfully migrated:</Text>
                  <ul>
                    <li>{migrationResult.migrated_courses.length} course completions</li>
                    <li>{migrationResult.migrated_collections.length} collection completions</li>
                    <li>{migrationResult.migrated_certificates.length} certificates</li>
                    <li>{migrationResult.migrated_evaluations.length} evaluations</li>
                    <li>{migrationResult.migrated_educations.length} education records</li>
                    <li>{migrationResult.migrated_pathways.length} pathways</li>
                    <li>Total records: {migrationResult.total_records}</li>
                  </ul>
                </>
              }
              message="Migration Complete"
              showIcon
              type="success"
            />
            <Button
              onClick={() => navigate(userPath(id))}
              type="primary"
            >
              Return to User
            </Button>
          </Space>
        )}
      </Space>
    </Card>
  );
};
