import { CheckCircleFilled, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Form, FormProps, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { GetNetworkResponse, getOrganizations, GetOrganizationsResponse } from 'api';
import { InputForm, InputItem } from 'components/forms';
import { useEffect, useState } from 'react';

import AddOrganizationToNetworkModal from './add_organization_to_network_modal';
import { STATE_LISTS } from './components/network_default_states/state_options';

interface NetworkFormProps {
  initialData?: GetNetworkResponse;
  name: string;
  onFinish: NonNullable<FormProps['onFinish']>;
}

const { Item } = Form;

export const NetworkForm = ({ initialData, name, onFinish }: NetworkFormProps) => {
  const [form] = useForm();
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [networkOrganizationData, setNetworkOrganizationData] = useState<
    GetOrganizationsResponse[] | undefined
  >([]);

  useEffect(() => {
    // Load some data on initial load
    refetchOrganizationData('', '200');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchOrganizationData = async (searchName: string, pageSize = '20') => {
    if (isFetching) return;
    setIsFetching(true);
    const organizationData = await getOrganizations('1', pageSize, {
      name: searchName ? searchName : undefined,
      network: true
    });
    setNetworkOrganizationData(organizationData.data);

    setIsFetching(false);
  };

  return (
    <InputForm
      form={form}
      initialValues={initialData}
      name={name}
      onFinish={onFinish}
    >
      <InputItem
        label="Name"
        name="name"
        required
      />

      <Item label="Default Comparison States">
        <Form.List name="default_comparison_states">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div
                  style={{
                    alignItems: 'baseline',
                    display: 'flex',
                    gap: '8px',
                    width: '100%'
                  }}
                >
                  <Form.Item
                    key={key}
                    name={[name]}
                    style={{
                      width: '100%'
                    }}
                  >
                    <Select
                      allowClear
                      optionFilterProp="label"
                      optionLabelProp="label"
                      showSearch
                    >
                      {STATE_LISTS.map(state => {
                        const isSelected =
                          initialData?.default_comparison_states.includes(state.state_code) ===
                          true;
                        return (
                          <Select.Option
                            key={state.state_code}
                            label={state.full_name}
                            value={state.state_code}
                          >
                            <Flex
                              justify="space-between"
                              style={{ color: isSelected ? '#982dac' : 'inherit' }}
                            >
                              {state.full_name}
                              {isSelected && <CheckCircleFilled />}
                            </Flex>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add state
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>
      <Item label="Organization Managers">
        <Form.List name="organization_managers">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <div
                  style={{
                    alignItems: 'baseline',
                    display: 'flex',
                    gap: '8px',
                    width: '100%'
                  }}
                >
                  <Form.Item
                    key={key}
                    name={[name, 'id']}
                    style={{
                      width: '100%'
                    }}
                  >
                    <Select
                      allowClear
                      defaultValue={initialData?.organization_managers[key]?.id}
                      filterOption={() => true}
                      notFoundContent={isFetching ? <Spin size="small" /> : undefined}
                      onSearch={name => refetchOrganizationData(name, '20')}
                      showSearch
                    >
                      {networkOrganizationData?.map(organization => (
                        <Select.Option
                          key={organization.id}
                          value={organization.id}
                        >
                          {organization.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}

              <Item>
                <Button
                  block
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                  type="dashed"
                >
                  Add Network Manager
                </Button>
              </Item>
            </>
          )}
        </Form.List>
      </Item>

      <Item label="Add Organization Member">
        <Item name="organization_members">
          <AddOrganizationToNetworkModal
            isModalOpen={isAddMemberModalOpen}
            setIsModalOpen={setIsAddMemberModalOpen}
          />
          <Item>
            <Button
              block
              icon={<PlusOutlined />}
              onClick={() => setIsAddMemberModalOpen(true)}
              type="dashed"
            >
              Add Network Member
            </Button>
          </Item>
        </Item>
      </Item>
    </InputForm>
  );
};
