import { Card, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  getCourse,
  getCourseRatings,
  GetCourseRatingsResponse,
  getCourseRegistrations,
  GetCourseRegistrationsResponse,
  GetCourseResponse,
  useFetch
} from 'api';
import { CardEntry, CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { UserLink } from 'components/common';
import { DateFormatter } from 'components/date_formatter';
import PageStickyHeader from 'components/page_sticky_header';
import { ActionsColumn, DataTable } from 'components/tables';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

const renderTags = (
  tags: GetCourseResponse['bipoc_communities' | 'lgbq_communities' | 'tgnc_communities']
) => (
  <>
    {tags.map((tag, i) => (
      <Tag key={i}>{tag}</Tag>
    ))}
  </>
);

const externalReferencesColumns: ColumnsType<
  GetCourseResponse['external_accreditation_references'][0]
> = [
  {
    dataIndex: 'vendor_name',
    key: 'vendor_name',
    title: 'Vendor name'
  },
  {
    dataIndex: 'vendor_resource_id',
    key: 'vendor_resource_id',
    title: 'Resource ID'
  }
];

const financialDisclosureColumns: ColumnsType<GetCourseResponse['financial_disclosures'][0]> = [
  {
    dataIndex: 'disclosure_text',
    key: 'disclosure_text',
    title: 'Financial Disclosures'
  }
];

const ratingColumns: ColumnsType<GetCourseRatingsResponse> = [
  {
    dataIndex: 'rating',
    key: 'rating',
    title: 'Rating'
  },
  {
    dataIndex: 'comment',
    key: 'comment',
    title: 'Comment'
  },
  {
    key: 'user',
    render: (_value, record) => (
      <>
        <Link to={paths.userPath(record.user.id)}>
          {`${record.user.first_name} ${record.user.last_name}`}
        </Link>
        <p>{record.user.email}</p>
      </>
    ),
    title: 'User'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetCourseRatingsResponse['created_at']) => <DateFormatter value={value} />,
    title: 'Created at'
  }
];

const registrationColumns: ColumnsType<GetCourseRegistrationsResponse> = [
  {
    key: 'user',
    render: (_value, record) => <UserLink user={record.user} />,
    title: 'User'
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value: GetCourseRegistrationsResponse['created_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Created at'
  },
  {
    dataIndex: 'completed_at',
    key: 'completed_at',
    render: (value: GetCourseRegistrationsResponse['completed_at']) => (
      <DateFormatter value={value} />
    ),
    title: 'Completed at'
  },
  {
    dataIndex: 'total_seconds_tracked',
    key: 'total_seconds_tracked',
    title: 'Seconds tracked'
  },
  {
    render: (_value, record) => (
      <ActionsColumn
        record={record}
        showPath={paths.courseRegistrationPath(record.id.toString())}
      />
    ),
    title: 'Actions'
  }
];

export const CoursePage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getCourse, [id]);

  const tabContent = [
    {
      children: data?.data ? (
        <>
          <CardEntryList
            fieldsMap={[
              { key: 'id', title: 'ID' },
              { key: 'full_title', title: 'Full title' },
              { key: 'short_title', title: 'Short title' },
              { key: 'published', title: 'Published' },
              { key: 'author', title: 'Author' },
              { key: 'organization_name', title: 'Organization name' },
              {
                key: 'organization',
                render: (org: GetCourseResponse['organization']) =>
                  org !== null ? <Link to={paths.organizationPath(org.id)}>{org.name}</Link> : '--',
                title: 'Access restriction'
              },
              {
                key: 'bipoc_communities',
                render: renderTags,
                title: 'BIPOC communitites'
              },
              {
                key: 'lgbq_communities',
                render: renderTags,
                title: 'LGBQ communitites'
              },
              {
                key: 'tgnc_communities',
                render: renderTags,
                title: 'TGNC communitites'
              },
              { key: 'professional_groups', title: 'Professional groups' },
              { key: 'year', title: 'Year' },
              { key: 'link', title: 'Link' },
              { key: 'scormcloud_id', title: 'Scormcloud ID' },
              { key: 'content_medium', title: 'Content medium' },
              { key: 'format_category', title: 'Format category' },
              { key: 'ce_credits', title: 'CE credits' },
              { key: 'ce_credit_types', title: 'CE credit types' },
              {
                key: 'ce_accrediting_associations',
                title: 'CE accrediting associations'
              },
              { key: 'ce_provider', title: 'CE provider' },
              { key: 'hours', title: 'Hours' },
              { key: 'description', title: 'Description' },
              {
                key: 'learning_objectives',
                render: (learning_objectives: GetCourseResponse['learning_objectives']) => (
                  <>
                    {learning_objectives.map((o, i) => (
                      <Tag key={i}>{o.name}</Tag>
                    ))}
                  </>
                ),
                title: 'Learning objectives'
              },
              { key: 'group_training', title: 'Group training' },
              { key: 'created_at', title: 'Created at', type: 'date' },
              { key: 'updated_at', title: 'Updated at', type: 'date' }
            ]}
            values={data.data as Required<typeof data.data>}
          />

          <CardEntry title="Created by">
            {data.data.created_by ? (
              <Link to={paths.userPath(data.data.created_by.id)}>
                {`${data.data.created_by.first_name} ${data.data.created_by.last_name}`}
              </Link>
            ) : (
              <span style={{ color: 'grey' }}>undefined</span>
            )}
          </CardEntry>
          <CardEntry title="Updated by">
            {data.data.updated_by ? (
              <Link to={paths.userPath(data.data.updated_by.id)}>
                {`${data.data.updated_by.first_name} ${data.data.updated_by.last_name}`}
              </Link>
            ) : (
              <span style={{ color: 'grey' }}>undefined</span>
            )}
          </CardEntry>
        </>
      ) : (
        <Spin size="large" />
      ),
      key: 'course',
      label: 'Course'
    },
    {
      children: data?.data ? (
        <Table
          bordered
          columns={externalReferencesColumns}
          dataSource={data.data.external_accreditation_references}
          rowKey="id"
        />
      ) : (
        <Spin size="large" />
      ),
      key: 'external_accreditation_references',
      label: 'External accreditation references'
    },
    {
      children: data?.data ? (
        <Table
          bordered
          columns={financialDisclosureColumns}
          dataSource={data.data.financial_disclosures}
          pagination={false}
          rowKey="id"
        />
      ) : (
        <Spin size="large" />
      ),
      key: 'financial_disclosures',
      label: 'Financial disclosures'
    },
    {
      children: data?.data ? (
        <DataTable
          columns={ratingColumns}
          getMethod={getCourseRatings}
          getParams={{
            course_id: data.data.id
          }}
        />
      ) : (
        <Spin size="large" />
      ),
      key: 'ratings',
      label: 'Ratings'
    },
    {
      children: data?.data ? (
        data.data.scormcloud_id !== null ? (
          <DataTable
            columns={registrationColumns}
            getMethod={getCourseRegistrations}
            getParams={{
              course_scormcloud_id: data.data.scormcloud_id
            }}
          />
        ) : (
          "This course doesn't have SCORM cloud course linked to it"
        )
      ) : (
        <Spin size="large" />
      ),
      key: 'registrations',
      label: 'Registrations'
    }
  ];

  const topBarSections = [
    {
      label: 'ID',
      value: data?.data?.id.toString() ?? '--'
    },
    {
      label: 'Course',
      value: data?.data?.short_title ?? '--'
    }
  ];

  return (
    <div className="site-card-border-less-wrapper">
      {data?.data ? (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <CardHeader
              editPath={paths.courseEditPath(id)}
              title={`Course ${data.data.short_title}`}
            />
          }
        >
          <PageStickyHeader
            tabContent={tabContent}
            topBarSections={topBarSections}
          />
        </Card>
      ) : (
        <Spin size="large" />
      )}
    </div>
  );
};
