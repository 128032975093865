import type { DatePickerProps } from 'antd';
import type { AxiosResponse } from 'axios';
import type { RequireAtLeastOne } from 'type-fest';

import { instance } from '.';

interface CreateUserParams {
  user: {
    create_hubspot_contact: boolean;
    delayed_invite_date?: DatePickerProps['value'] | null;
    email: string;
    first_name?: string;
    is_clinical: boolean;
    last_name?: string;
    organization_memberships: {
      employee_id: string;
      member_role: 'member' | 'network_manager' | 'superuser';
      organization_id: string;
      organization_license_tier_id: string;
      status: 'activated' | 'deactivated';
    }[];
    role: 'admin' | 'api_client' | 'provider';
    send_invite_emails: boolean;
    user_info_attributes: {
      npi?: string;
    };
  };
}

interface CreateUserResponse {
  auth0_id: string;
  city: null;
  completed_onboarding: boolean;
  created_at: string;
  email: string;
  first_name: string | null;
  hubspot_id: string | null;
  id: string;
  invite_link: string;
  is_active: boolean;
  is_clinical: boolean;
  last_name: string | null;
  permissions: UserPermission[];
  requires_assessment: boolean;
  role: 'admin' | 'api_client' | 'provider';
  state: null;
  status: 'created';
}

export interface GetUserResponse extends User {}

export interface GetUsersResponse {
  auth0_id: string;
  badges: {
    badge_criteria_id: string;
    community_of_interest_score: number;
    created_at: string;
    earned_at: string;
    education_score: number;
    id: string;
    image: {
      url: string;
    };
    is_active: boolean;
    lived_experience_score: number;
    professional_experience_score: number;
    score_level: 'awareness' | 'excellence' | 'placeholder' | 'proficiency';
    score_type: ScoreType;
    total_score: number;
    user_info_id: string;
  }[];
  city: string | null;
  completed_onboarding: boolean;
  completed_onboarding_at: string | null;
  created_at: string;
  email: string;
  first_name: string | null;
  hubspot_id: number;
  id: string;
  invite_link: string;
  invited_at: string | null;
  is_active: boolean;
  is_clinical: boolean;
  last_name: string | null;
  member_roles: ('member' | 'network_manager' | 'superuser')[];
  organization_memberships: {
    id: string;
    member_role: 'member' | 'network_manager' | 'superuser';
    organization_id: string;
    organization_name: string;
    status: 'activated' | 'deactivated';
  }[];
  permissions: UserPermission[];
  requires_assessment: boolean;
  role: 'admin' | 'provider';
  sign_up_source: 'admin' | 'self_service' | null;
  state: string | null;
  status: 'activated' | 'created' | 'deactivated' | 'inferred';
}

export interface UpdateUserParams {
  user: RequireAtLeastOne<{
    completed_onboarding: boolean;
    email: string | null;
    first_name: string | null;
    hubspot_id: number | string | null;
    is_clinical: boolean;
    last_name: string | null;
    organization_memberships_attributes: {
      created_at: string;
      employee_id: string;
      id: string;
      is_active: boolean;
      member_role: 'member' | 'network_manager' | 'superuser';
      organization_id: string;
      organization_license_tier: {
        available_licenses: number;
        created_at: string;
        id: string;
        license_tier: {
          id: string;
          name: string;
        };
        organization: {
          id: string;
          name: string;
        };
        total_licenses: number;
        updated_at: string;
      };
      organization_license_tier_id: string;
      status: 'activated' | 'deactivated';
      updated_at: string;
      user_id: string;
    }[];
    role: 'admin' | 'api_client' | 'provider';
    status: 'activated' | 'deactivated';
    user_info_attributes: {
      id: string;
      npi: string | null;
    };
  }>;
}

interface UpdateUserResponse {
  auth0_id: string;
  city: null;
  completed_onboarding: boolean;
  created_at: string;
  email: string;
  first_name: string | null;
  hubspot_id: number | null;
  id: string;
  invite_link: string;
  is_active: boolean;
  is_clinical: boolean;
  last_name: string | null;
  permissions: UserPermission[];
  requires_assessment: boolean;
  role: 'admin' | 'api_client' | 'provider';
  state: null;
  status: 'activated' | 'created' | 'deactivated';
}

interface MigrateUserDataResponse {
  migrated_certificates: string[];
  migrated_collections: string[];
  migrated_courses: string[];
  migrated_educations: string[];
  migrated_evaluations: string[];
  migrated_pathways: string[];
  total_records: number;
}

export const bulkUpdateUsers = (params: FormData) =>
  instance.post<BulkEditUserResponse>('/admin/users/bulk_edit', params).then(res => res.data);

export const createUser = (params: CreateUserParams) =>
  instance
    .post<
      ApiResponse<CreateUserResponse>,
      AxiosResponse<ApiResponse<CreateUserResponse>>,
      CreateUserParams
    >('/admin/users', params)
    .then(res => res.data);

export const deleteUser = (id: string) => instance.delete<''>(`/admin/users/${id}`);

export const exportUsers = (getParams?: object) =>
  instance
    .get<ApiResponse<BlobPart>>('/admin/users/export', {
      params: {
        ...getParams
      },
      responseType: 'blob'
    })
    .then(res => res.data);

export const getUser = (id: string) =>
  instance.get<ApiResponse<GetUserResponse>>(`/admin/users/${id}`).then(res => res.data);

export const getUsers = (page?: string, pageSize?: string, getParams?: object) =>
  instance
    .get<ApiResponse<GetUsersResponse[], IndexPageMeta>>('/admin/users', {
      params: {
        ...getParams,
        page,
        per_page: pageSize
      }
    })
    .then(res => res.data);

export const importUsersCsvFile = (params: FormData) =>
  instance.post<ImportUserResponse>('/admin/users/import', params).then(res => res.data);

export const migrateUserData = (id: string, fromUserId: string) =>
  instance
    .post<MigrateUserDataResponse>(`/admin/users/${id}/migrate_data`, null, {
      params: {
        from_user_id: fromUserId
      }
    })
    .then(res => res.data);

export const sendInviteEmail = (id: string) => instance.post<''>(`/admin/users/${id}/send_invite`);

export const updateUser = (id: string, params: UpdateUserParams) =>
  instance
    .patch<
      ApiResponse<UpdateUserResponse>,
      AxiosResponse<ApiResponse<UpdateUserResponse>>,
      UpdateUserParams
    >(`/admin/users/${id}`, params)
    .then(res => res.data);
