import {
  AreaChartOutlined,
  ArrowRightOutlined,
  BookOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  CloseOutlined,
  LockFilled,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Card, Dropdown, Popconfirm, Progress, Spin, Table, Tag, Tooltip } from 'antd';
import {
  exportUsers,
  getOrganization,
  getOrganizationLicenseTiers,
  GetOrganizationResponse,
  getUsers,
  GetUsersResponse,
  useFetch
} from 'api';
import { getLicenseCohorts } from 'api/license_cohorts';
import { deleteOrganizationMembership } from 'api/organization_memberships';
import { CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { DateFormatter } from 'components/date_formatter';
import PageStickyHeader from 'components/page_sticky_header';
import { ActionsColumn, DataTable } from 'components/tables';
import { userColumns } from 'pages/users/user_components';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import * as paths from 'utils/paths';

import AssignmentsTable from './components/assignments_table';
import {
  communicationInfoOverviewColumns,
  licenseCohortColumns,
  organizationOverviewColumns,
  orgLicenseColumns
} from './organization_components';

const ProgressDividerLine = styled.div`
  position: absolute;
  width: 2px;
  background: rgba(0, 0, 0, 0.3);
  height: 16px;
  z-index: 2;
  top: 3px;
`;

export const OrganizationPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getOrganization, [id]);

  const formatDate = (date: Date) =>
    date
      .toLocaleDateString('en-US', {
        month: '2-digit',
        // eslint-disable-next-line sort-keys
        day: '2-digit',
        year: 'numeric'
      })
      .replace(/\//g, '_');

  const tabContent = [
    {
      children: data?.data ? (
        <CardEntryList
          fieldsMap={[
            { key: 'name', title: 'Name' },
            { key: 'id', title: 'ID' },
            { key: 'enforced_settings', title: 'Enforced settings' },
            { key: 'education_hours', title: 'Education hours' },
            {
              key: 'education_completed_count',
              title: 'Educations completed'
            },
            { key: 'average_course_score', title: 'Average course score' },
            { key: 'sso_connection_id', title: 'SSO connection ID' },
            { key: 'sso_connection_name', title: 'SSO connection name' },
            { key: 'hubspot_id', title: 'Hubspot ID' },
            { key: 'referral_code', title: 'Referral code' },
            { key: 'unlimited_license', title: 'Unlimited license' },
            { key: 'network', title: 'Network organization' },
            { key: 'direct_contract', title: 'Direct contract' },
            {
              key: 'organization_external_identifiers',
              render: (
                organization_external_identifiers: GetOrganizationResponse['organization_external_identifiers']
              ) =>
                organization_external_identifiers.length > 0
                  ? organization_external_identifiers.map((identifier, i) => (
                      <Tooltip
                        key={i}
                        title={`Source: ${identifier.source ?? 'not set'}`}
                      >
                        <Tag>{`${identifier.identifier_type}: ${identifier.identifier}`}</Tag>
                      </Tooltip>
                    ))
                  : '–',
              title: 'External Identifiers'
            },
            {
              key: 'permissions',
              render: (permissions: GetOrganizationResponse['permissions']) => (
                <>
                  {permissions.map((permission, i) => (
                    <Tag key={i}>{permission}</Tag>
                  ))}
                </>
              ),
              title: 'Organization permissions'
            },
            {
              key: 'organization_email_domains',
              render: (
                organization_email_domains: GetOrganizationResponse['organization_email_domains']
              ) => (
                <>
                  {organization_email_domains.map((domain, i) => (
                    <Tag key={i}>{domain.domain}</Tag>
                  ))}
                </>
              ),
              title: 'Organization email domains'
            },
            {
              key: 'member_networks',
              render: (member_networks: GetOrganizationResponse['member_networks']) =>
                member_networks.length > 0
                  ? member_networks.map((network, i) => (
                      <Link
                        key={i}
                        to={paths.networkPath(network.id)}
                      >
                        <Tag>{network.name}</Tag>
                      </Link>
                    ))
                  : '–',
              title: 'Member of networks'
            }
          ]}
          values={data.data}
        />
      ) : (
        <Spin size="large" />
      ),
      key: 'organization',
      label: 'Organization'
    },
    {
      children: data?.data ? (
        <Table
          columns={[
            {
              key: 'verified_inclusive',
              render: (record: GetOrganizationResponse) =>
                record.verified_inclusive ? (
                  <Tag
                    color="purple"
                    style={{ padding: '0 0.5rem 0 0.45rem' }}
                  >
                    <CheckCircleFilled /> Verified inclusive
                  </Tag>
                ) : (
                  <Tag
                    color="volcano"
                    style={{ padding: '0 0.5rem 0 0.45rem' }}
                  >
                    <CloseCircleFilled /> Not yet met
                  </Tag>
                ),
              title: 'Status'
            },
            {
              key: 'total_eligible_users',
              render: (record: GetOrganizationResponse) => record.user_totals.clinical,
              title: 'Total eligible users (Clinical)'
            },
            {
              key: 'percentage_onboarded',
              render: (record: GetOrganizationResponse) => (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      fontWeight: 'bold',
                      gap: '0.25rem',
                      position: 'relative'
                    }}
                  >
                    {record.verified_inclusive_criteria.percentage_onboarded_met ? (
                      <CheckCircleFilled style={{ color: '#389e0d' }} />
                    ) : (
                      <CloseCircleFilled style={{ color: '#cf1322' }} />
                    )}
                    <ProgressDividerLine
                      style={{
                        left: `calc(${record.verified_inclusive_criteria.percentage_onboarded_threshold}% + 0.1rem)`
                      }}
                    />
                    <Progress
                      percent={record.user_totals.onboarded.clinical_percentage}
                      percentPosition={{ align: 'start', type: 'inner' }}
                      size={['100%', 20]}
                      strokeColor={
                        record.verified_inclusive_criteria.percentage_onboarded_met
                          ? '#389e0d'
                          : '#cf1322'
                      }
                    />
                    <span style={{ fontWeight: 'normal' }}>
                      {record.user_totals.onboarded.clinical.toLocaleString()}
                    </span>
                  </div>
                  <br />
                  <span>
                    <strong>Goal:</strong>{' '}
                    {record.verified_inclusive_criteria.percentage_onboarded_threshold}% of eligible
                    providers
                  </span>
                </div>
              ),
              title: 'Onboarded %'
            },
            {
              key: 'percentage_benchmarked',
              render: (record: GetOrganizationResponse) => {
                const firstIsNotMet = !record.verified_inclusive_criteria.percentage_onboarded_met;
                return (
                  <div>
                    <div
                      style={{
                        color: firstIsNotMet ? '#777' : 'inherit',
                        display: 'flex',
                        fontWeight: 'bold',
                        gap: '0.25rem',
                        position: 'relative'
                      }}
                    >
                      {firstIsNotMet ? (
                        <LockFilled style={{ color: '#777' }} />
                      ) : record.verified_inclusive_criteria.percentage_benchmarked_met ? (
                        <CheckCircleFilled style={{ color: '#389e0d' }} />
                      ) : (
                        <CloseCircleFilled style={{ color: '#cf1322' }} />
                      )}
                      <ProgressDividerLine
                        style={{
                          left: `${record.verified_inclusive_criteria.percentage_benchmarked_threshold}%`
                        }}
                      />
                      <Progress
                        percent={record.verified_inclusive_criteria.percentage_benchmarked}
                        percentPosition={{ align: 'start', type: 'inner' }}
                        size={['100%', 20]}
                        strokeColor={
                          firstIsNotMet
                            ? '#777'
                            : record.verified_inclusive_criteria.percentage_benchmarked_met
                            ? '#389e0d'
                            : '#cf1322'
                        }
                      />
                      <span style={{ fontWeight: 'normal' }}>
                        {record.user_totals.benchmarked.total.toLocaleString()}
                      </span>
                    </div>
                    <br />
                    <span>
                      <strong>Goal:</strong>{' '}
                      {record.verified_inclusive_criteria.percentage_benchmarked_threshold}% of
                      onboarded providers
                    </span>
                  </div>
                );
              },
              title: 'Benchmarked %'
            }
          ]}
          dataSource={[data.data]}
          pagination={false}
          style={{ marginBottom: 20 }}
        />
      ) : (
        <Spin size="large" />
      ),
      key: 'verified_inclusive',
      label: 'Verified Inclusive Org'
    },
    {
      children: data?.data ? (
        <Table
          columns={[
            {
              key: 'total',
              render: (record: GetOrganizationResponse['user_totals']) => record.total,
              title: 'Total'
            },
            {
              key: 'inferred',
              render: (record: GetOrganizationResponse['user_totals']) => record.inferred,
              title: 'Inferred'
            },
            {
              key: 'created',
              render: (record: GetOrganizationResponse['user_totals']) => record.created,
              title: 'Created'
            },
            {
              key: 'activated',
              render: (record: GetOrganizationResponse['user_totals']) => record.activated,
              title: 'Activated'
            },
            {
              key: 'completed_onboarding',
              render: (record: GetOrganizationResponse['user_totals']) => record.onboarded.total,
              title: 'Completed onboarding'
            },
            {
              key: 'deactivated',
              render: (record: GetOrganizationResponse['user_totals']) => record.deactivated,
              title: 'Deactivated'
            }
          ]}
          dataSource={[data.data.user_totals]}
          pagination={false}
          style={{ marginBottom: 20 }}
        />
      ) : (
        <Spin size="large" />
      ),
      key: 'user_totals',
      label: 'User Totals'
    },
    {
      children: (
        <DataTable
          columns={orgLicenseColumns}
          getMethod={getOrganizationLicenseTiers}
          getParams={{
            organization_id: id
          }}
          pagination={false}
          style={{ marginBottom: 20 }}
        />
      ),
      key: 'license_tiers',
      label: 'License Tiers'
    },
    {
      children: data?.data ? (
        <Table
          bordered
          columns={[
            {
              key: 'name',
              render: (record: GetOrganizationResponse['managed_networks'][0]) => (
                <Link to={paths.networkPath(record.id)}>{record.name}</Link>
              ),
              title: 'Name'
            },
            {
              key: 'created_at',
              render: (record: GetOrganizationResponse['managed_networks'][0]) => (
                <DateFormatter value={record.created_at} />
              ),
              title: 'Created at'
            },
            {
              key: 'total_organization_members',
              render: (record: GetOrganizationResponse['managed_networks'][0]) =>
                record.total_organization_members,
              title: '# of organizations'
            }
          ]}
          dataSource={data.data.managed_networks.map(entry => ({
            key: entry.id,
            ...entry
          }))}
          pagination={false}
          style={{ marginBottom: 20 }}
        />
      ) : (
        <Spin size="large" />
      ),
      disabled: data?.data?.managed_networks.length === 0,
      key: 'managed_networks',
      label: 'Managed Networks'
    },
    {
      children: data?.data ? (
        <Table
          bordered
          columns={[
            {
              key: 'name',
              render: (record: GetOrganizationResponse['network_member_organizations'][0]) => (
                <Link to={paths.organizationPath(record.id)}>{record.name}</Link>
              ),
              title: 'Name'
            },
            {
              dataIndex: 'direct_contract',
              key: 'direct_contract',
              render: (direct_contract: GetOrganizationResponse['direct_contract']) => (
                <Tag color={direct_contract ? 'green' : 'blue'}>
                  {direct_contract ? 'Contracted' : 'Inferred'}
                </Tag>
              ),
              title: 'Direct contract'
            },
            {
              key: 'created_at',
              render: (record: GetOrganizationResponse['network_member_organizations'][0]) => (
                <DateFormatter value={record.created_at} />
              ),
              title: 'Created at'
            }
          ]}
          dataSource={data.data.network_member_organizations.map(entry => ({
            key: entry.id,
            ...entry
          }))}
          pagination={false}
          style={{ marginBottom: 20 }}
        />
      ) : (
        <Spin size="large" />
      ),
      disabled: data?.data?.network_member_organizations.length === 0,
      key: 'network_member_organizations',
      label: 'Network Member Organizations'
    },
    {
      children: (
        <DataTable
          columns={licenseCohortColumns}
          getMethod={getLicenseCohorts}
          getParams={{
            organization_id: id
          }}
        />
      ),
      key: 'license_cohorts',
      label: 'License Cohorts'
    },
    {
      children: <AssignmentsTable organizationId={id} />,
      key: 'assigned_education',
      label: 'Assignments'
    },
    {
      children: data?.data ? (
        <DataTable
          columns={userColumns.map(column => {
            if (column.title === 'Actions') {
              return {
                render: (_value, record: GetUsersResponse) => {
                  const removeFromOrganization = async () => {
                    const organizationMembership = record.organization_memberships.find(
                      membership => membership.organization_id === id
                    );
                    if (!organizationMembership) return;
                    await deleteOrganizationMembership(organizationMembership.id);
                    window.location.reload();
                  };
                  return (
                    <ActionsColumn
                      customPaths={{
                        'Assign education': record.email
                          ? paths.bookmarkOrgAssignmentPath(id, record.email.replace(/\+/g, '%2B'))
                          : '',
                        remove: (
                          <Popconfirm
                            cancelText="No"
                            disabled={record.organization_memberships.length === 1}
                            okText="Yes"
                            onConfirm={removeFromOrganization}
                            title="Are you sure you want to remove this user from this organization?"
                          >
                            <PopoverButton
                              className={
                                record.organization_memberships.length === 1 ? 'disabled' : ''
                              }
                            >
                              Remove from organization
                            </PopoverButton>
                          </Popconfirm>
                        ),
                        Score: paths.userScoringPath(record.id)
                      }}
                      editPath={paths.userEditPath(record.id)}
                      record={record}
                      showPath={paths.userPath(record.id)}
                    />
                  );
                },
                title: 'Actions',
                width: '60px'
              };
            } else if (column.key === 'full_name') {
              return {
                ...column,
                sorter: true
              };
            }
            return column;
          })}
          exportFileName={`users_${data.data.name.replaceAll(' ', '_')}_export_${formatDate(
            new Date()
          )}`}
          exportMethod={exportUsers}
          filters={[
            {
              key: 'user_ids[]',
              label: 'ID'
            },
            {
              key: 'first_name',
              label: 'First name'
            },
            {
              key: 'last_name',
              label: 'Last name'
            },
            {
              key: 'email',
              label: 'E-mail'
            },
            {
              key: 'npi',
              label: 'NPI'
            }
          ]}
          getMethod={getUsers}
          getParams={{ organization_id: id }}
          perPage={15}
        />
      ) : (
        <Spin size="large" />
      ),
      key: 'members',
      label: 'Members'
    },
    {
      children: data?.data ? (
        <Table
          bordered
          columns={organizationOverviewColumns}
          dataSource={data.data.detailed_overview.map(entry => ({
            key: entry.invite_date,
            ...entry
          }))}
          pagination={false}
        />
      ) : (
        <Spin size="large" />
      ),
      key: 'invites',
      label: 'Invites'
    },
    {
      children: data?.data ? (
        <Table
          bordered
          columns={communicationInfoOverviewColumns}
          dataSource={data.data.communication_infos.map(entry => ({
            key: entry.email_type,
            ...entry
          }))}
          pagination={false}
        />
      ) : (
        <Spin size="large" />
      ),
      key: 'email_overview',
      label: 'Email overview'
    }
  ];

  const topBarSections = [
    {
      label: 'ID',
      value: data?.data?.id ?? '--'
    },
    {
      label: 'Name',
      value: data?.data?.name ?? '--'
    }
  ];

  return (
    <div className="site-card-border-less-wrapper">
      {data?.data && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          style={{ minHeight: 'calc(100vh - 111px)' }}
          title={
            <CardHeader
              editPath={paths.organizationEditPath(data.data.id)}
              title={` Organization ${data.data.name}`}
            >
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'assign_educations',
                      label: (
                        <Link to={paths.bookmarkOrgAssignmentPath(id)}>
                          <BookOutlined /> Assign educations
                        </Link>
                      )
                    },
                    {
                      key: 'reports',
                      label: (
                        <Link to={paths.organizationReportsPath(id)}>
                          <AreaChartOutlined /> Reports
                        </Link>
                      )
                    },

                    {
                      type: 'divider'
                    },
                    {
                      key: 'transfer',
                      label: (
                        <Link to={paths.organizationTransferPath(id)}>
                          <ArrowRightOutlined /> Migrate users
                        </Link>
                      )
                    },
                    {
                      key: 'bulk_removal',
                      label: (
                        <Link to={paths.organizationBulkRemovalPath(id)}>
                          <CloseOutlined /> Remove users
                        </Link>
                      )
                    },
                    {
                      key: 'bulk_add',
                      label: (
                        <Link to={paths.organizationBulkAddPath(id)}>
                          <PlusOutlined /> Add users
                        </Link>
                      )
                    }
                  ]
                }}
              >
                <Button
                  size="large"
                  style={{}}
                >
                  Actions
                </Button>
              </Dropdown>
            </CardHeader>
          }
        >
          <PageStickyHeader
            tabContent={tabContent}
            topBarSections={topBarSections}
          />
        </Card>
      )}
    </div>
  );
};

export const PopoverButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 0.875rem;
  background: none;
  cursor: pointer;
  color: inherit;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
