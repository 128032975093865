import { EditTwoTone, UndoOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useState } from 'react';

const { Item } = Form;

interface Props {
  credit: string;
  id: string;
}

export const EditCreditColumn = ({ credit, id }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return (
      <div className="edit-credit-wrapper">
        <Item
          className="edit-credit-input"
          initialValue={credit}
          name={[id, 'credits']}
          style={{ margin: 'auto auto', maxWidth: '50px', padding: 0 }}
          valuePropName="value"
        >
          <Input />
        </Item>
        <Button
          aria-label="Cancel"
          onClick={() => setIsEditing(false)}
          style={{ padding: 0 }}
          type="text"
        >
          <UndoOutlined style={{ color: '#982dac' }} />
        </Button>
      </div>
    );
  } else {
    return (
      <div>
        {credit}
        <Button
          aria-label="Edit"
          onClick={() => setIsEditing(true)}
          style={{ padding: 0 }}
          type="text"
        >
          <EditTwoTone twoToneColor="#982dac" />
        </Button>
      </div>
    );
  }
};
