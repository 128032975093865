import styled from 'styled-components';

export const PathwayImage = styled.img`
  max-width: 600px;
  width: 100%;
  height: auto;
`;

export const PlainList = styled.ul`
  margin: 0;
  padding-inline-start: 20px;
`;
