import { CheckCircleFilled, CloseCircleFilled, EditFilled } from '@ant-design/icons';
import { Flex, Select, Tag } from 'antd';
import { DarkModeContext } from 'layouts/app_layout';
import { ReactNode, useContext } from 'react';

import useStateManagement from './state_management';
import { STATE_LISTS } from './state_options';
import { getTagColors, selectInputStyle, ThemedButton } from './theme';

const NetworkDefaultStates = ({
  defaultComparisonStates,
  networkId
}: {
  defaultComparisonStates: string[];
  networkId: string;
}) => {
  const isDarkMode = useContext(DarkModeContext);
  const { handleSave, isEditing, onCancel, setIsEditing, setStates, stateList, states } =
    useStateManagement(defaultComparisonStates, networkId);

  const onChange = (value: string) => {
    setStates(Array.from(new Set([...states, value])).sort());
  };

  const onRemove = (value: string) => {
    setStates(states.filter(state => state !== value).sort());
  };

  const tagColors = getTagColors(isDarkMode, isEditing);

  return (
    <Flex
      gap="4px 0"
      wrap
    >
      {states.map<ReactNode>(stateName => {
        const stateOption = STATE_LISTS.find(state => state.state_code === stateName);
        return (
          <Tag
            key={stateName}
            closable={isEditing}
            closeIcon={isEditing ? <CloseCircleFilled style={{ color: '#fff' }} /> : undefined}
            color={isEditing ? '#982dac' : 'default'}
            onClose={() => onRemove(stateName)}
            style={{
              ...tagColors,
              userSelect: 'none'
            }}
          >
            {stateOption?.full_name ?? stateName}
          </Tag>
        );
      })}
      {isEditing ? (
        <>
          <Select
            onChange={onChange}
            optionFilterProp="value"
            placeholder="Select a state"
            showSearch
            style={selectInputStyle}
            value={null}
          >
            {stateList.map(stateOption => (
              <Select.Option
                key={stateOption.state_code}
                value={stateOption.state_code}
              >
                {stateOption.full_name}
              </Select.Option>
            ))}
          </Select>
          <ThemedButton
            icon={<CheckCircleFilled />}
            onClick={handleSave}
          >
            Save
          </ThemedButton>
          <ThemedButton
            icon={<CloseCircleFilled />}
            onClick={onCancel}
            style={{ marginLeft: '0.5rem' }}
            variant="secondary"
          >
            Cancel
          </ThemedButton>
        </>
      ) : (
        <ThemedButton
          icon={<EditFilled />}
          onClick={() => setIsEditing(true)}
        >
          Edit
        </ThemedButton>
      )}
    </Flex>
  );
};

export default NetworkDefaultStates;
