import { InfoCircleOutlined } from '@ant-design/icons';
import { Collapse, DatePicker, Modal, Table, Tooltip } from 'antd';
import { getBookmark, GetBookmarkResponse, getBookmarks, updateBookmark } from 'api';
import { bookmarksColumns } from 'components/bookmark_columns';
import { DataTable } from 'components/tables';
import dayjs from 'dayjs';
import { DarkModeContext } from 'layouts/app_layout';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import UserCell from './user_cell';

interface Props {
  organizationId: string;
}

interface BookmarkStyleProps {
  $isDarkMode: boolean;
}

const EditorBlock = styled.div`
  display: grid;
  gap: 1rem;
`;

const AssignmentEditorBlock = styled.div<BookmarkStyleProps>`
  padding: 1rem;
  background: #f9f0fd;
  border-radius: 0.5rem;
  display: grid;
  gap: 0.5rem;
  font-size: 0.75rem;

  ${({ $isDarkMode }) =>
    $isDarkMode &&
    `
    background: #1D131F;
  `}
`;

const InfoIcon = styled(InfoCircleOutlined)`
  margin-left: 0.5rem;
`;

const AssignmentTitle = styled.div<BookmarkStyleProps>`
  font-size: 1rem;
  font-weight: 600;
  color: #83039c;
  margin-bottom: 0.5rem;

  ${({ $isDarkMode }) =>
    $isDarkMode &&
    `
    color: #fff;
  `}
`;

const UsersAffectedLabel = styled.div<BookmarkStyleProps>`
  font-size: 0.75rem;
  font-weight: 400;
  background: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;

  ${({ $isDarkMode }) =>
    $isDarkMode &&
    `
    color: #fff;
    background: #2b1630;
  `}
`;

const AssignmentCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0.5rem 1rem !important;
    border: none;
    font-size: 0.75rem;
    align-items: center;
  }

  .ant-collapse-expand-icon {
    height: 1rem !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  tr {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }
`;

const AssignmentUsersTable = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 0.5rem 1rem !important;
    font-size: 0.75rem;
  }

  .ant-table-tbody > tr > td {
    padding: 0.5rem 1rem !important;
    font-size: 0.75rem;
  }

  a {
    font-weight: 500;
  }

  p {
    margin: 0;
  }
`;

// Add new interfaces for grouping
interface BookmarkGroup {
  bookmarkIds: {
    id: string;
    user_id?: string;
  }[];
  endDate: string | null;
  isAllUsers: boolean;
  resourceId: string;
  resourceName: string;
  resourceType: string;
  startDate: string | null;
}

const AssignmentsTable = ({ organizationId }: Props) => {
  const isDarkMode = useContext(DarkModeContext);
  const [refetchBookmarksTrigger, setRefetchBookmarksTrigger] = useState(0);
  const assignmentColumns = bookmarksColumns(setRefetchBookmarksTrigger);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bookmarkGroups, setBookmarkGroups] = useState<BookmarkGroup[]>([]);

  const groupBookmarks = (bookmarks: GetBookmarkResponse[]) => {
    const groups = new Map<string, BookmarkGroup>();

    bookmarks.forEach(bookmark => {
      // Create a key based on the grouping criteria
      const key = `${bookmark.resource_id}-${bookmark.start_date}-${bookmark.end_date}`;
      if (!groups.has(key)) {
        groups.set(key, {
          bookmarkIds: [],
          endDate: bookmark.end_date,
          isAllUsers: bookmark.user_id === null,
          resourceId: bookmark.resource_id,
          resourceName:
            'name' in bookmark.resource
              ? bookmark.resource.name
              : 'full_title' in bookmark.resource
              ? bookmark.resource.full_title
              : 'Unknown',
          resourceType: 'name' in bookmark.resource ? 'Course' : 'Collection',
          startDate: bookmark.start_date
        });
      }

      groups
        .get(key)!
        .bookmarkIds.push({ id: bookmark.id, user_id: bookmark.user_id ?? undefined });
    });

    return Array.from(groups.values());
  };

  const handleOpenEditModal = async (ids: string[]) => {
    const bookmarks: GetBookmarkResponse[] = [];
    // Use Promise.all to wait for all bookmark fetches
    await Promise.all(
      ids.map(async id => {
        const response = await getBookmark(id);
        bookmarks.push(response.data!);
      })
    );

    setBookmarkGroups(groupBookmarks(bookmarks));
    setIsModalOpen(true);
  };

  const handleSaveChanges = async () => {
    let hasErrors = false;
    await Promise.all(
      bookmarkGroups.map(group =>
        Promise.all(
          group.bookmarkIds.map(id =>
            updateBookmark(id.id, {
              bookmark: {
                end_date: group.endDate !== null ? dayjs(group.endDate) : null,
                start_date: group.startDate !== null ? dayjs(group.startDate) : null
              }
            })
          )
        )
      )
    )
      .catch(error => {
        hasErrors = true;
        toast.error(`Failed to update assignments: ${error ?? 'Check console for details.'}`);
        console.error(error);
      })
      .finally(() => {
        if (!hasErrors) {
          toast.success('Assignments updated successfully.');
          setBookmarkGroups([]);
          setRefetchBookmarksTrigger(refetchBookmarksTrigger + 1);
          setIsModalOpen(false);
        }
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setBookmarkGroups([]);
  };

  const handleStartDateChanged = (date: dayjs.Dayjs | null, groupIndex: number) => {
    setBookmarkGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[groupIndex] = {
        ...newGroups[groupIndex],
        // If new start date is after current end date, clear end date
        endDate:
          date &&
          newGroups[groupIndex].endDate !== null &&
          date.isAfter(dayjs(newGroups[groupIndex].endDate))
            ? null
            : newGroups[groupIndex].endDate,
        startDate: date ? date.format('YYYY-MM-DD') : null
      };
      return newGroups;
    });
  };

  const handleEndDateChanged = (date: dayjs.Dayjs | null, groupIndex: number) => {
    setBookmarkGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[groupIndex] = {
        ...newGroups[groupIndex],
        endDate: date ? date.format('YYYY-MM-DD') : null
      };
      return newGroups;
    });
  };

  return (
    <>
      <DataTable
        batchActions={[
          {
            isAsync: false,
            label: 'Bulk edit dates',
            onClick: handleOpenEditModal
          }
        ]}
        columns={assignmentColumns}
        getMethod={getBookmarks}
        getParams={{
          assigned_by_id: organizationId,
          assigned_by_type: 'Organization'
        }}
        refetchTrigger={refetchBookmarksTrigger}
        rowKey="id"
      />
      <Modal
        okText="Save changes"
        onCancel={handleCancel}
        onOk={handleSaveChanges}
        open={isModalOpen}
        title="Bulk Edit Assignments"
      >
        <EditorBlock>
          {bookmarkGroups.map((group, index) => (
            <AssignmentEditorBlock
              key={index}
              $isDarkMode={isDarkMode}
            >
              <AssignmentTitle $isDarkMode={isDarkMode}>
                {group.resourceName}
                <Tooltip title={`${group.resourceType} ${group.resourceId}`}>
                  <InfoIcon />
                </Tooltip>
              </AssignmentTitle>
              <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: 10 }}>
                <span>Start:</span>
                <DatePicker
                  disabled={
                    group.startDate !== null &&
                    dayjs(group.startDate).isBefore(dayjs().startOf('day'))
                  }
                  disabledDate={date => date.isBefore(dayjs().subtract(1, 'day').endOf('day'))}
                  onChange={date => handleStartDateChanged(date, index)}
                  value={group.startDate !== null ? dayjs(group.startDate) : null}
                />
                <span>End:</span>
                <DatePicker
                  disabled={group.startDate === null}
                  disabledDate={date =>
                    (group.startDate !== null
                      ? date.isBefore(dayjs(group.startDate).endOf('day'))
                      : false) || date.isBefore(dayjs().endOf('day'))
                  }
                  onChange={date => handleEndDateChanged(date, index)}
                  value={group.endDate !== null ? dayjs(group.endDate) : null}
                />
              </div>
              <UsersAffectedLabel $isDarkMode={isDarkMode}>
                {group.isAllUsers ? (
                  <>
                    Affects <strong>all</strong> organization users
                  </>
                ) : (
                  <AssignmentCollapse>
                    <Collapse.Panel
                      key={`${index}-${group.resourceId}`}
                      header={
                        <>
                          <strong>{group.bookmarkIds.length}</strong> individual assignments
                          affected
                        </>
                      }
                    >
                      <AssignmentUsersTable
                        columns={[
                          {
                            dataIndex: 'id',
                            key: 'id',
                            title: 'ID'
                          },
                          {
                            dataIndex: 'user_id',
                            key: 'user_id',
                            render: (user_id: string) => <UserCell user_id={user_id} />,
                            title: 'User'
                          }
                        ]}
                        dataSource={group.bookmarkIds.map(id => ({
                          id: id.id,
                          user_id: id.user_id
                        }))}
                        pagination={false}
                      />
                    </Collapse.Panel>
                  </AssignmentCollapse>
                )}
              </UsersAffectedLabel>
            </AssignmentEditorBlock>
          ))}
        </EditorBlock>
      </Modal>
    </>
  );
};

export default AssignmentsTable;
