import { ColumnsType } from 'antd/es/table';
import { getCourseCollectionCompletions } from 'api/course_collection_completions';
import { DateFormatter } from 'components/date_formatter';
import { DataTable } from 'components/tables';
import { Link } from 'react-router-dom';
import { courseCollectionPath } from 'utils/paths';

interface CourseCollectionCompletion {
  completed_at: string;
  completed_course_count: number;
  course_collection: {
    id: string;
    name: string;
  };
  course_collection_id: string;
  created_at: string;
  id: string;
  started_at: string;
  updated_at: string;
  user: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  };
  user_id: string;
}

const courseCollectionCompletionsColumns: ColumnsType<CourseCollectionCompletion> = [
  {
    key: 'course_collection',
    render: (_value, record) => (
      <Link to={courseCollectionPath(record.course_collection.id)}>
        {record.course_collection.name}
      </Link>
    ),
    title: 'Collection Name'
  },
  {
    dataIndex: 'completed_course_count',
    key: 'completed_course_count',
    title: 'Completed Courses'
  },
  {
    key: 'started_at',
    render: (_value, record) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        hideTimezone
        value={record.started_at}
      />
    ),
    sorter: true,
    title: 'Started At'
  },
  {
    key: 'completed_at',
    render: (_value, record) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        hideTimezone
        value={record.completed_at}
      />
    ),
    sorter: true,
    title: 'Completed At'
  },
  {
    key: 'created_at',
    render: (_value, record) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        hideTimezone
        value={record.created_at}
      />
    ),
    sorter: true,
    title: 'Created At'
  },
  {
    key: 'updated_at',
    render: (_value, record) => (
      <DateFormatter
        format="MM/DD/YYYY hh:mm a"
        hideTimezone
        value={record.updated_at}
      />
    ),
    sorter: true,
    title: 'Updated At'
  }
];

interface Props {
  user_id: string;
}

const CourseCollectionCompletionsTable = ({ user_id }: Props) => (
  <DataTable
    columns={courseCollectionCompletionsColumns}
    getMethod={getCourseCollectionCompletions}
    getParams={{
      user_id
    }}
    rowKey="id"
  />
);

export default CourseCollectionCompletionsTable;
