import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { Avatar, Breadcrumb, Button, Col, ConfigProvider, Layout, Row, Switch, theme } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { ReactComponent as Logo } from 'assets/logotype.svg';
import { SignOut } from 'components/auth/sign_out';
import { ErrorToast } from 'components/error_toast';
import { LoadingSpin } from 'components/loading_spin';
import { SideMenu } from 'components/side_menu';
import { createContext, useCallback, useEffect, useState } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { Link } from 'react-router-dom-v5-compat';
import { AdminRoutes } from 'routes';
import { store, useStore } from 'store';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { isAdmin } from 'utils/user';

const { darkAlgorithm, defaultAlgorithm } = theme;
const { Content, Footer, Sider } = Layout;
const collapseWidthBreakpoint = 750;

const checkShouldBeCollapsed = () => window.innerWidth < collapseWidthBreakpoint;

export const DarkModeContext = createContext(false);

// General admin panel insides layout
const AppLayout = ({ route }: RouteConfigComponentProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout: auth0Logout,
    user
  } = useAuth0();

  // Wrap the logout function to set our flag
  const logout = useCallback(
    (options?: LogoutOptions) => {
      setLoggingOut(true);
      auth0Logout(options);
    },
    [auth0Logout]
  );

  const { isSignedIn, userData } = useStore('isSignedIn', 'userData');

  // @ts-expect-error
  const breadcrumbs = useBreadcrumbs(AdminRoutes);

  // Collapse side menu on window width rescaling below breakpoint
  useEffect(() => {
    function handleResize() {
      if (checkShouldBeCollapsed()) {
        setIsCollapsed(true);
      }
    }

    updateTheme(localStorage.getItem('isDarkMode') === 'dark');
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    // Don't proceed if we're signed in, loading, or actively logging out
    if (isSignedIn || isLoading || loggingOut) return;

    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: { redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI }
      });
      return;
    }

    getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI
      }
    }).then(token => {
      store.dispatch('signIn', {
        errCallback: () => {
          store.dispatch('signOut');
          logout({
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
            logoutParams: {
              returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI
            }
          });
        },
        token
      });
    });
  }, [
    isSignedIn,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    loggingOut
  ]);

  const updateTheme = (darkMode: boolean) => {
    setIsDarkMode(darkMode);
    document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
    localStorage.setItem('isDarkMode', darkMode ? 'dark' : 'light');
  };

  if (!userData || isLoading) return <LoadingSpin />;

  if (!isAdmin(userData)) {
    store.dispatch('signOut');
    logout({
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI
      }
    });
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorError: '#d92d20',
          colorPrimary: '#982dac'
        }
      }}
    >
      <DarkModeContext.Provider value={isDarkMode}>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            collapsed={isCollapsed}
            collapsible
            onCollapse={setIsCollapsed}
            theme="light"
            trigger={null}
          >
            <div
              style={{
                display: 'flex',
                height: 64,
                justifyContent: 'center',
                justifyItems: 'center'
              }}
            >
              <Logo
                style={{
                  height: '70%',
                  marginTop: '10px',
                  verticalAlign: 'center',
                  width: '70%'
                }}
              />
            </div>
            <Button
              color="primary"
              icon={isCollapsed ? <RightOutlined /> : <LeftOutlined />}
              onClick={() => setIsCollapsed(!isCollapsed)}
              style={{
                fontSize: '16px',
                width: '100%'
              }}
              type="text"
              variant="filled"
            >
              {!isCollapsed && <span style={{ fontSize: '0.8rem' }}>Collapse</span>}
            </Button>
            <SideMenu collapsed={isCollapsed} />
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: '0 24px'
              }}
            >
              <Row>
                <Col
                  flex="1"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    paddingLeft: 15
                  }}
                >
                  <Breadcrumb
                    separator=">"
                    style={{
                      fontSize: 'large'
                    }}
                  >
                    {breadcrumbs.map(b => (
                      <Breadcrumb.Item key={b.key}>
                        <Link to={b.key}>{b.breadcrumb}</Link>
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </Col>
                <Col flex="0.08">
                  <Avatar src={user?.picture} />
                </Col>
                <Col
                  flex="0.08"
                  style={{ color: isDarkMode ? 'white' : 'black' }}
                >
                  {userData.email}
                </Col>
                <Col
                  flex="0.1"
                  offset="1"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    padding: '2px'
                  }}
                >
                  <span style={{ marginRight: '2px' }}>☀️</span>
                  <Switch
                    defaultChecked={isDarkMode}
                    onChange={updateTheme}
                  />
                  <span style={{ marginLeft: '2px' }}>🌒</span>
                </Col>
                <Col offset="1">{isSignedIn && <SignOut logoutAuth0={logout} />}</Col>
              </Row>
            </Header>
            <Content className="site-layout-background">
              {route && renderRoutes(route.routes)}
              <ErrorToast />
            </Content>
            <Footer style={{ padding: '15px 50px', textAlign: 'center' }}>
              Violet Admin Panel
            </Footer>
          </Layout>
        </Layout>
      </DarkModeContext.Provider>
    </ConfigProvider>
  );
};

export default AppLayout;
