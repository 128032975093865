import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, theme } from 'antd';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const TopBarWrapper = styled.div<{ theme: typeof theme }>`
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  background: ${({ theme }) => theme.useToken().token.colorFillSecondary};
  color: ${({ theme }) => theme.useToken().token.colorText};

  .border-right {
    border-right: 1px solid ${({ theme }) => theme.useToken().token.colorText};
    padding-right: 1rem;
  }

  .link-button-section {
    margin-left: auto;
  }
`;

const copiableSections = ['ID', 'EMAIL', 'E-MAIL', 'NPI'];

interface Props {
  sections: {
    label: string;
    value: string;
  }[];
}

const PageTopBar = ({ sections }: Props) => {
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.success('Copied to clipboard', { autoClose: 1000 });
  };

  return (
    <TopBarWrapper theme={theme}>
      {sections.map((section, index) => (
        <div
          key={index}
          className={index === sections.length - 1 ? '' : 'border-right'}
        >
          <strong>{section.label}:</strong> {section.value}
          {copiableSections.includes(section.label.toUpperCase()) && (
            <Button
              aria-label={`Copy ${section.label} to clipboard`}
              onClick={() => copyToClipboard(section.value)}
              shape="circle"
              style={{
                alignItems: 'center',
                display: 'inline-flex',
                fontSize: '0.75rem',
                height: '1.25rem',
                justifyContent: 'center',
                marginLeft: '0.35rem',
                minWidth: '1.25rem',
                padding: '0',
                width: '1.25rem'
              }}
            >
              <CopyOutlined />
            </Button>
          )}
        </div>
      ))}
      <div className="link-button-section">
        <Button
          aria-label="Copy link to current view"
          icon={<LinkOutlined />}
          onClick={() => copyToClipboard(window.location.href)}
          size="small"
          variant="solid"
        >
          Share link
        </Button>
      </div>
    </TopBarWrapper>
  );
};

export default PageTopBar;
