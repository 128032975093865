import { Button } from 'antd';
import { DarkModeContext } from 'layouts/app_layout';
import { ReactNode, useContext } from 'react';
import { CSSProperties } from 'styled-components';

const THEME_COLORS = {
  dark: {
    primary: '#982dac',
    secondary: '#667085',
    text: '#FFF',
    textInverse: '#FFF'
  },
  light: {
    primary: '#982dac',
    secondary: '#667085',
    text: '#FFF',
    textInverse: '#000'
  }
} as const;

export const getTagColors = (isDarkMode: boolean, isEditing: boolean) => ({
  backgroundColor: isEditing
    ? isDarkMode
      ? THEME_COLORS.dark.primary
      : THEME_COLORS.light.primary
    : undefined,
  color: isEditing
    ? isDarkMode
      ? THEME_COLORS.dark.text
      : THEME_COLORS.light.text
    : isDarkMode
    ? THEME_COLORS.dark.textInverse
    : THEME_COLORS.light.textInverse
});

const baseButtonStyle: CSSProperties = {
  fontSize: '0.75rem',
  fontWeight: 'bold',
  height: 22,
  letterSpacing: '0.03em',
  textTransform: 'uppercase'
};

export const selectInputStyle: CSSProperties = {
  height: 22,
  marginInlineEnd: 8,
  verticalAlign: 'top'
};

interface ThemedButtonProps {
  children: ReactNode;
  icon: ReactNode;
  onClick: () => void;
  style?: CSSProperties;
  variant?: 'primary' | 'secondary';
}

export const ThemedButton = ({
  children,
  icon,
  onClick,
  style,
  variant = 'primary'
}: ThemedButtonProps) => {
  const isDarkMode = useContext(DarkModeContext);
  const colors = THEME_COLORS[isDarkMode ? 'dark' : 'light'];

  const buttonStyle: CSSProperties = {
    ...baseButtonStyle,
    backgroundColor: colors[variant],
    borderColor: colors[variant],
    color: colors.text,
    ...style
  };

  return (
    <Button
      icon={<span style={{ color: colors.text }}>{icon}</span>}
      onClick={onClick}
      style={buttonStyle}
    >
      <span style={{ color: colors.text }}>{children}</span>
    </Button>
  );
};
