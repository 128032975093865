import { Card, FormProps } from 'antd';
import { createNetwork } from 'api';
import { CardHeader } from 'components/cards';
import { useNavigate } from 'react-router-dom-v5-compat';
import * as paths from 'utils/paths';

import { NetworkForm } from './network_form';

export const NetworkCreatePage = () => {
  const navigate = useNavigate();

  const onFinish: FormProps<{
    default_comparison_states: string[];
    name: string;
    organization_managers: { id: string }[] | undefined;
  }>['onFinish'] = values =>
    createNetwork({
      network: {
        default_comparison_states: Array.from(new Set(values.default_comparison_states)).sort(),
        name: values.name,
        organization_manager_ids: values.organization_managers?.map(member => member.id)
      }
    }).then(() => {
      navigate(paths.networksPath);
    });

  return (
    <Card title={<CardHeader title="Create Network" />}>
      <NetworkForm
        name="network-create"
        onFinish={onFinish}
      />
    </Card>
  );
};
